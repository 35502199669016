<template>
  <SdPage
    id="business-settings-page"
    :class="$viewport.lt.md ? 'mobile':''"
    class="pb-4"
  >
    <SdDiscardChangesDialog
      v-if="uiFlags.leavingToRouteNext!==null"
      message="Are you sure you want to leave and discard changes?"
      @close="uiFlags.leavingToRouteNext=null"
      @discard="leavingToRouteNext()"
    />
    <SdPageHeader title="Settings" />
    <div
      class="row no-gutters business-settings-row mt-2"
    >
      <div :class="$viewport.lt.md ? 'w-100' : 'col-12'">
        <el-tabs
          :tab-position="$viewport.lt.md ? 'top' : 'left'"
          :active-name="getRelevantSection()"
          :stretch="$viewport.lt.md"
          @input="setRelevantSection"
        >
          <el-tab-pane
            label="Business"
            name="business"
          >
            <BusinessSettingsBusinessTab
              v-if="payloadRef"
              :ref="TABS.BUSINESS"
              :class="{'pt-3': $viewport.lt.md}"
              :payload="payloadRef"
              :auth-user="authUser"
              :business="businessRef"
              :is-owner="isOwner"
              :rules="rules"
              @update-business-source="updateBusinessSource"
            />
          </el-tab-pane>
          <el-tab-pane
            label="Showings"
            name="showings"
          >
            <BusinessSettingsShowingsTab
              v-if="payloadRef && screeningCriteriaRef"
              :ref="TABS.SHOWINGS"
              :class="{'pt-3': $viewport.lt.md}"
              :auth-user="authUser"
              :payload="payloadRef"
              :is-owner="isOwner"
              :screening-criteria="screeningCriteriaRef"
              :rules="rules"
            />
          </el-tab-pane>
          <el-tab-pane
            v-if="isOwner && ai_leasing_agent_enabled_at"
            label="AI Leasing Assistant"
            name="ai-leasing-assistant"
            lazy
          >
            <BusinessSettingsAILeasingAssistantTab
              v-if="payloadRef"
              :ref="TABS.AI_LEASING_ASSISTANT"
              :class="{'pt-3': $viewport.lt.md}"
              :payload="payloadRef"
            />
          </el-tab-pane>
          <el-tab-pane
            label="ILS Syndication"
            name="syndication"
            lazy
          >
            <BusinessSettingsSyndicationTab
              v-if="payloadRef"
              :class="{'pt-3': $viewport.lt.md}"
              :business="businessRef"
              :is-owner="isOwner"
            />
          </el-tab-pane>
          <el-tab-pane
            label="Integrations"
            name="integrations"
          >
            <BusinessSettingsIntegrationTab
              v-if="payloadRef"
              :class="{'pt-3': $viewport.lt.md}"
              :is-owner="isOwner"
              :payload="payloadRef"
              :business="businessRef"
              :business-source-payload="businessSourcePayloadRef"
              :business-source="businessSourceRef"
              @init="init"
              @init-business-source="initBusinessSource"
              @update-business-source="updateBusinessSource"
            />
          </el-tab-pane>
          <div
            v-if="uiFlags.isLoading"
            class="text-center mt-4 mt-md-0"
          >
            <ElSpinner
              color="primary"
            />
          </div>
        </el-tabs>
      </div>
    </div>
  </SdPage>
</template>

<script>
import { onMounted, reactive, ref } from '@vue/composition-api';
import cloneDeep from 'lodash.clonedeep';
import { getSettingValueByKey } from '@/utils/SettingUtil';
import { isStandardTypePlan } from '@/utils/PlanUtil';
import SdPageHeader from '@/components/common/layout/SdPageHeader';
import SdPage from '@/components/common/layout/SdPage';
import SdDiscardChangesDialog from '@/components/common/form/SdDiscardChangesdDialog.vue';
import BusinessSettingsBusinessTab from '@/components/settings/BusinessSettingsBusinessTab';
import BusinessSettingsShowingsTab from '@/components/settings/BusinessSettingsShowingsTab';
import BusinessSettingsIntegrationTab from '@/components/settings/BusinessSettingsIntegrationTab';
import BusinessSettingsSyndicationTab from '@/components/settings/BusinessSettingsSyndicationTab';
import BusinessSettingsAILeasingAssistantTab from '@/components/settings/BusinessSettingsAILeasingAssistantTab';
import Role from '../../../constants/Role';
import StateOptions from '../../../constants/StateOptions';
import CountryOptions from '../../../constants/CountryOptions';
import PostalCodePattern from '../../../constants/PostalCodePattern';

export default {
  components: {
    BusinessSettingsAILeasingAssistantTab,
    SdDiscardChangesDialog,
    BusinessSettingsBusinessTab,
    BusinessSettingsShowingsTab,
    BusinessSettingsIntegrationTab,
    BusinessSettingsSyndicationTab,
    SdPage,
    SdPageHeader,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.originalStateRef.payload) {
      next();
      return;
    }

    const originalStateClone = cloneDeep({ ...this.originalStateRef.payload });
    const hasChanges = Object.values(TABS).some((tabName) => this.$refs[tabName]?.hasChanges(originalStateClone));

    if (hasChanges) {
      this.uiFlags.leavingToRouteNext = next;
      return;
    }
    next();
  },
  setup(props, context) {
    const store = context.root.$store;
    const plan = context.root.$store.getters['Auth/plan'];
    const authUser = store.state.Auth.user;
    const businessRef = ref({});
    const businessSourceRef = ref({});
    const maximumLeadsPerTourRef = ref(getSettingValueByKey('maximum_concurrent_leads_per_showing', null));
    const screeningCriteriaRef = ref();
    const uiFlags = reactive({
      leavingToRouteNext: null,
      isLoading: false,
    });
    const payloadRef = ref(null);
    const businessSourcePayloadRef = ref(null);
    const rules = getRules();
    const isOwner = authUser.role === Role.ACCOUNT_OWNER;
    const originalStateRef = ref({
      payload: null,
    });

    onMounted(init);

    store.subscribe((mutation) => {
      if (mutation.type.includes('Auth/setBusiness')) {
        updatePayload({ ...mutation.payload });
      }
    });

    return {
      init,
      maximumLeadsPerTourRef,
      uiFlags,
      payloadRef,
      originalStateRef,
      StateOptions,
      CountryOptions,
      rules,
      authUser,
      isOwner,
      Role,
      screeningCriteriaRef,
      businessSourcePayloadRef,
      businessSourceRef,
      businessRef,
      initBusinessSource,
      updatePayload,
      updateBusinessSource,
      leavingToRouteNext,
      isStandardTypePlan: isStandardTypePlan(plan),
      getRelevantSection,
      setRelevantSection,
      updateUserStore,
      TABS,
    };

    function leavingToRouteNext() {
      uiFlags.leavingToRouteNext();
      uiFlags.leavingToRouteNext = null;
    }

    async function init() {
      try {
        uiFlags.isLoading = true;
        await initBusinessSource();
        businessRef.value = await store.dispatch('Business/get');
        const {
          name,
          short_name,
          billing_email,
          is_concurrent_tours_allowed,
          contact_email = null,
          contact_phone = null,
          contact_phone_extension = null,
          should_show_contact = false,
          video_tours_enabled_at,
          device_integration_enabled_at,
          device_connected,
          codebox_connected,
          screening_criteria_url,
          default_screening_criteria,
          default_application_form_url,
          default_general_notes,
          schedule_notice_limit,
          address,
          logo,
          logo_url,
          website_url,
          show_listing_apply_button,
          is_lead_confirmation_required_for_open_house,
          token_created_at,
          faq_url,
          identity_verification,
          self_showing_schedule_notice_limit,
          self_showing_contractor_lockbox_enabled_at,
          income_voucher,
          screening_templates,
          inquiry_notification_email,
          sync_application_url,
          showing_hours,
          website_chat,
          training_materials,
          ai_leasing_agent_enabled_at,
          ai_leasing_agent_phone_number,
          devices,
        } = businessRef.value;
        payloadRef.value = {
          name,
          short_name,
          billing_email,
          new_billing_email: billingMailIsSameAsAccountOwnerMail() ? null : billing_email,
          is_concurrent_tours_allowed,
          contact_email,
          contact_phone,
          contact_phone_extension,
          should_show_contact,
          video_tours_enabled: !!video_tours_enabled_at,
          device_integration_enabled: !!device_integration_enabled_at,
          device_connected,
          codebox_connected,
          screening_criteria_url,
          default_screening_criteria,
          default_application_form_url,
          default_general_notes,
          schedule_notice_limit,
          address,
          logo,
          logo_url,
          website_url,
          show_listing_apply_button,
          is_lead_confirmation_required_for_open_house,
          token_created_at,
          faq_url,
          identity_verification,
          self_showing_schedule_notice_limit,
          self_showing_contractor_lockbox_enabled: !!self_showing_contractor_lockbox_enabled_at,
          website_chat,
          training_materials,
          income_voucher,
          inquiry_notification_email,
          screening_templates,
          sync_application_url,
          showing_hours,
          devices,
          ai_leasing_agent_enabled_at,
          ai_leasing_agent_phone_number,
        };

        updateOriginalState();
        screeningCriteriaRef.value = store.getters['ScreeningCriteria/get'] || (await store.dispatch('ScreeningCriteria/load'));
      } finally {
        uiFlags.isLoading = false;
      }
    }

    async function updateUserStore() {
      await store.dispatch('Auth/me');
    }

    async function initBusinessSource() {
      try {
        businessSourceRef.value = await store.dispatch('BusinessSource/index');
      } catch (e) {
        businessSourceRef.value = {};
      }
      businessSourcePayloadRef.value = {
        id: businessSourceRef.value.id,
        auto_sync: Boolean(businessSourceRef.value.synced_at),
      };
    }

    function getRelevantSection() {
      const section = ref(context.root.$route.params.section || false);
      if ([
        'business',
        'showings',
        'integrations',
        'syndication',
      ].includes(section.value)) {
        return section.value;
      }

      if (section.value === 'ai-leasing-assistant' && isOwner) {
        return section.value;
      }

      window.history.pushState(null, null, '/profile/business-settings/business');
      return 'business';
    }

    function setRelevantSection(sectionName) {
      window.history.pushState(null, null, `/profile/business-settings/${sectionName}`);
    }

    function billingMailIsSameAsAccountOwnerMail() {
      return businessRef.value.billing_email === authUser.first_business_user_email;
    }

    async function updateBusinessSource(payload) {
      if (!payload) {
        return;
      }
      const current = businessSourceRef.value;
      const sameAutoSync = Boolean(current.synced_at) === payload.auto_sync;
      if (!current.business_source_identifier || sameAutoSync) {
        return;
      }

      await store.dispatch('BusinessSource/update', payload);
      await initBusinessSource();
    }

    async function updatePayload(fields) {
      if (!payloadRef.value) {
        return;
      }
      const payload = payloadRef.value;
      Object.keys(fields).forEach((key) => {
        if (key in payload) {
          payloadRef.value[key] = fields[key];
        }
      });

      updateOriginalState();
    }

    function updateOriginalState() {
      originalStateRef.value.payload = cloneDeep({ ...payloadRef.value });
    }

    function getRules() {
      return {
        address: {
          line1: {
            required: true,
            type: 'string',
            message: 'Address line 1 is required',
          },
          line2: {
            type: 'string',
          },
          city: {
            required: true,
            type: 'string',
            message: 'City is required',
          },
          state: {
            validator: (rule, value, callback) => {
              if (payloadRef.value.address.country !== 'US') {
                return callback();
              }

              if (StateOptions.some((state) => state.value === value)) {
                return callback();
              }

              return callback('State is required');
            },
          },
          postal_code: {
            validator: (rule, value, callback) => {
              if (payloadRef.value.address.country === 'US') {
                if (PostalCodePattern.test(value)) {
                  return callback();
                }
              } else if (value.length) {
                return callback();
              }

              return callback('Postal code is required');
            },
          },
          country: {
            required: true,
            type: 'string',
            enum: CountryOptions.map(({ value }) => value),
            message: 'Country is required',
          },
        },
      };
    }
  },
};

const TABS = {
  BUSINESS: 'businessTab',
  SHOWINGS: 'showingsTab',
  AI_LEASING_ASSISTANT: 'aiLeasingAssistant',
};
</script>
<style lang="scss">
@import '@/styles/utils';

.el-table__header-wrapper {
  th > .cell {
    padding-left: 1rem;
    padding-right: 1rem;
    color: gray-color(darker);
    font-size: $--font-size-base;
  }

  tr {
    th:first-of-type .cell {
      padding-left: 0.25rem;
    }

    th:last-of-type .cell {
      padding-right: 0.25rem;
    }
  }
}

.el-table__body-wrapper {
  border-top: 1px solid gray-color(light);
  border-bottom: 1px solid gray-color(light);

  tr {
    td:first-of-type .cell {
      padding-left: 0.25rem;
    }

    td:last-of-type .cell {
      padding-right: 0.25rem;
    }
  }
}

.mobile .el-tabs {
  margin-top: 1rem;
}

#business-settings-page {
  @include generate-sidebar-tabs-vertical-design;
}
</style>
